import React from 'react';
import {
  PromptWrapper,
  ModelResponseWrapper,
} from '@/components/hallucination-index-2023/blooper-reel';
// import { ScrollWrap } from '@/components/common/scroll-wrap';

const modelResponseDataRowOne = [
  {
    modelName: 'GPT-4-0613',
    scoreName: 'Correctness score',
    score: 0.73,
    response:
      'The first ever video game with 3D graphics is considered to be "I, Robot" developed by Atari and released in 1984.',
  },
  {
    modelName: 'GPT-3.5-turbo-instruct',
    scoreName: 'Correctness score',
    score: 0.92,
    response:
      'The first ever video game with 3D graphics was Battlezone, released in 1980.',
  },
  {
    modelName: 'Mistral-7b-instruct-v0.1',
    scoreName: 'Correctness score',
    score: 0.12,
    response:
      'The first video game with 3D graphics was "Super Mario 64," released in 1996 for the Nintendo 64.',
  },
];

const modelResponseDataRowTwo = [
  {
    modelName: 'GPT-4-0613',
    scoreName: 'Correctness score',
    score: 0.92,
    response: `A "+" sign in chess notation signifies that a move has put the opponent's king in check.`,
  },
  {
    modelName: 'MPT-7b-instruct',
    scoreName: 'Correctness score',
    score: 0.21,
    response: 'The "+" sign is used to indicate a pawn.',
  },
  {
    modelName: 'Zephyr-7b-beta',
    scoreName: 'Correctness score',
    score: 0.92,
    response:
      'In chess notation, a "+" sign is used to indicate that the preceding move was made with check, meaning the king of the opponent is under immediate threat of capture. This is also known as checking the king. The move that includes a check is called a "checking move." The player who delivers the check is said to have "given check," while the player whose king is under threat is said to be "in check." The game continues until the king is moved out of check, the king is captured, or the king is checkmated (captured in such a way that the player cannot make another move to escape check).',
  },
];
const modelResponseDataRowThree = [
  {
    modelName: 'GPT-4-0613',
    scoreName: 'Correctness score',
    score: 0.94,
    response:
      'The probability of picking a white ball on the first draw is 3/8. After one white ball is drawn, there are now 7 balls left in the bowl, 2 of which are white. So the probability of drawing a white ball on the second draw is 2/7. The probability of both events happening is (3/8) * (2/7) = 6/56 = 3/28.',
  },
  {
    modelName: 'Falcon-40b-instruct',
    scoreName: 'Correctness score',
    score: 0.34,
    response: 'The probability that both balls are white is 1/3.',
  },
  {
    modelName: 'Falcon-40b-instruct',
    scoreName: 'Correctness score',
    score: 0.23,
    response: 'The probability that both balls are white is 1/2.',
  },
];

const LongTextBlooper = () => {
  return (
    <>
      <div className="mt-4 flex flex-wrap justify-between pb-2 text-xs opacity-70 md:text-sm lg:mt-6 xl:text-base">
        <div className="w-auto shrink">
          <p>Snippets from prompting LLMs</p>
        </div>
        <div className="w-auto shrink">
          <p>
            Dataset Used:{' '}
            <a
              className="text-hi-100 hover:underline"
              href="https://github.com/LAION-AI/Open-Assistant"
            >
              Open Assistant
            </a>
          </p>
        </div>
      </div>

      <div className="border-t border-hi-3">
        {/* <ScrollWrap minWidth={`1024px`}> */}
        <div className="grid grid-cols-1 gap-4 py-8 sm:py-10 lg:grid-cols-9 lg:py-12">
          <div className="relative lg:col-span-3">
            <PromptWrapper>
              <p className="text-xs font-light leading-relaxed text-dark-80 lg:text-sm">
                What was the first ever video game with 3D graphics?
              </p>
            </PromptWrapper>
          </div>

          {modelResponseDataRowOne.map((model, idx) => (
            <div className="flex flex-col justify-between lg:col-span-2">
              <ModelResponseWrapper
                modelName={model.modelName}
                correctnessScore={model.score}
                scoreName={model.scoreName}
              >
                {model.response}
              </ModelResponseWrapper>
            </div>
          ))}
        </div>
        {/* </ScrollWrap> */}
      </div>

      <div className="border-t border-hi-3">
        {/* <ScrollWrap minWidth={`1024px`}> */}
        <div className="grid grid-cols-1 gap-4 py-8 sm:py-10 lg:grid-cols-9 lg:py-12">
          <div className="relative lg:col-span-3">
            <PromptWrapper>
              <p className="text-xs font-light leading-relaxed text-dark-80 lg:text-sm">
                What does a "+" sign signify in chess notation?
              </p>
            </PromptWrapper>
          </div>

          {modelResponseDataRowTwo.map((model, idx) => (
            <div className="flex flex-col justify-between lg:col-span-2">
              <ModelResponseWrapper
                modelName={model.modelName}
                correctnessScore={model.score}
                scoreName={model.scoreName}
              >
                {model.response}
              </ModelResponseWrapper>
            </div>
          ))}
        </div>
        {/* </ScrollWrap> */}
      </div>

      <div className="border-t border-hi-3">
        {/* <ScrollWrap minWidth={`1024px`}> */}
        <div className="grid grid-cols-1 gap-4 py-8 sm:py-10 lg:grid-cols-9 lg:py-12">
          <div className="relative lg:col-span-3">
            <PromptWrapper>
              <p className="text-xs font-light leading-relaxed text-dark-80 lg:text-sm">
                Alice has a bowl with 8 balls. Three of the balls are white and
                the rest are black. Bob picks two balls from the bowl at random.
                What is the probability that both balls are white?
              </p>
            </PromptWrapper>
          </div>

          {modelResponseDataRowThree.map((model, idx) => (
            <div className="flex flex-col justify-between lg:col-span-2">
              <ModelResponseWrapper
                modelName={model.modelName}
                correctnessScore={model.score}
                scoreName={model.scoreName}
              >
                {model.response}
              </ModelResponseWrapper>
            </div>
          ))}
        </div>
        {/* </ScrollWrap> */}
      </div>
    </>
  );
};

export default LongTextBlooper;

import React, { useState, useEffect, useRef } from 'react';

const RevealDialog = ({ context }) => {
  const [showModal, setShowModal] = useState(false);

  // close on ESC
  useEffect(() => {
    const closeOnEscapeKeyDown = (e) => {
      if ((e.charCode || e.keyCode) === 27) {
        setShowModal(false);
      }
    };
    window.addEventListener('keydown', closeOnEscapeKeyDown);

    return () => {
      window.removeEventListener('keydown', closeOnEscapeKeyDown);
    };
  }, []);

  // add misclick handler for ref={modalRef}
  const modalRef = useRef();

  const handleClickOutside = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setShowModal(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  // prevent scroll when dialog is open
  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [showModal]);

  return (
    <>
      <button
        className="mt-3 bg-black px-4 py-1.5 text-sm text-white hover:bg-hi-100"
        onClick={() => setShowModal(true)}
      >
        Context
      </button>

      {showModal ? (
        <div className="fixed inset-0 z-20 overflow-y-auto">
          <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-14 text-center sm:block sm:p-0 lg:pt-20">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <div
              ref={modalRef}
              className="my-8 inline-block w-full max-w-4xl transform overflow-hidden rounded-lg bg-white text-left align-middle align-bottom shadow-xl transition-all lg:w-[720px] xl:w-[960px]"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div>
                  <textarea
                    className="w-full select-none"
                    readOnly
                    disabled
                    rows={20}
                  >
                    {context}
                  </textarea>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  className="mt-3 bg-black px-4 py-1.5 text-sm text-white hover:bg-hi-100"
                  onClick={() => setShowModal(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default RevealDialog;

import React from 'react';

const PromptWrapper = ({ children }) => {
  return (
    <div className="relative flex flex-col items-end">
      {/* <div className="w-4 border border-white rounded-sm z-10 h-4 bg-white absolute -bottom-1 left-10 rotate-45"></div> */}

      {/* <div className="w-5 rounded-[3px] z-0 -translate-x-0.5 translate-y-[0.5px] h-5 bg-hi-3 absolute -bottom-1 left-10 rotate-45"></div> */}
      <div className="relative w-full max-w-[420px] overflow-hidden rounded-2xl border border-hi-3 bg-white lg:max-w-full xl:rounded-3xl">
        <p className="bg-hi-2 px-3 py-2.5 text-[12px] md:text-[14px] lg:px-4 lg:py-3 lg:text-[16px] xl:px-6 xl:py-3.5">
          prompt
        </p>

        <div className="px-3 py-2.5 text-sm lg:px-4 lg:py-3 lg:text-base xl:px-6 xl:py-3.5">
          {children}
        </div>
      </div>
    </div>
  );
};

const ModelResponseWrapper = ({
  children,
  modelName,
  correctnessScore,
  scoreName,
}) => {
  return (
    <>
      <div className="relative flex flex-col items-end">
        <div className="absolute -bottom-1 right-10 z-10 h-4 w-4 rotate-45 rounded-sm border border-white bg-white"></div>
        <div className="absolute -bottom-1 right-10 z-0 h-5 w-5 translate-x-0.5 translate-y-[0.5px] rotate-45 rounded-[3px] bg-hi-3"></div>
        <div className="lg:max-w-auto relative w-full max-w-[300px] overflow-hidden rounded-2xl border border-hi-3 bg-white pb-4 xl:rounded-3xl">
          <p className="bg-hi-2 px-3 py-2.5 text-[12px] md:text-[14px] lg:px-4 lg:py-3 lg:text-[16px] xl:px-6 xl:py-3.5">
            Model Response
          </p>
          <div className="hide-scroll-bar max-h-[200px] overflow-scroll px-3 py-2.5 text-xs lg:px-4 lg:py-3 lg:text-sm xl:px-6 xl:py-3.5">
            {children}
          </div>
        </div>
      </div>

      <div className="mt-6 flex flex-col items-end text-sm">
        <div>{modelName}</div>
        <div className="flex flex-row items-end space-x-2">
          <div className="shrink text-right opacity-60">{scoreName}: </div>
          <div
            // style={{
            //   opacity: parseFloat(correctnessScore) * 2.5,
            // }}
            className={`${
              correctnessScore > 0.9
                ? 'text-green-500'
                : correctnessScore < 0.9 && correctnessScore > 0.55
                  ? 'text-hi-khaki'
                  : correctnessScore < 0.55 && correctnessScore > 0.45
                    ? 'text-hi-orange'
                    : 'text-red'
            } grow`}
          >
            {correctnessScore}
          </div>
        </div>
      </div>
    </>
  );
};

export { PromptWrapper, ModelResponseWrapper };
